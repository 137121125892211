<template>
	<div class="card">
		<div class="card-body">
			<a-tabs default-active-key="1">
				<a-tab-pane key="1" tab="Información General" value="Información General" force-render>
					<customersPartialsGeneral />
				</a-tab-pane>
				<a-tab-pane key="2" tab="Información Fiscal" value="Información Fiscal" force-render v-if="!isNewRecord">
					<customersPartialsTax />
				</a-tab-pane>
				<!-- <a-tab-pane key="4" tab="Forma de Contacto" value="Forma de Contacto" force-render v-if="!isNewRecord">
						<customersPartialsContacts />
					</a-tab-pane> -->
			</a-tabs>
		</div>
	</div>
</template>

<script lang="ts">
import utilities from '@/services/utilities'
import customersPartialsGeneral from '@/components/customers/partials/general'
import customersPartialsTax from '@/components/customers/partials/tax'
// import customersPartialsContacts from '@/components/customers/partials/contacts'

export default {
	name: 'customerDetailView',
	components: {
		customersPartialsGeneral,
		customersPartialsTax,
		// customersPartialsContacts,
	},
	computed: {
		isNewRecord() {
			return this.$route.params.id == 'new' ? true : false
		},
	},
	mounted() {
		if (utilities.objectValidate(this.$route, 'params.id', false) && !this.isNewRecord) {
			// Solicitamos carga de registro
			this.$store.dispatch('customers/GET_ONE', this.$route.params.id)
		}
	},
	methods: {
		reload() {
			this.$store.dispatch('customers/GET_ONE', this.$route.params.id)
		},
	},
}
</script>